import { Component, OnDestroy } from '@angular/core'
import { locales, Localization } from '@aa-app-localization'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { DebtorService } from '@app-services'
import { AuthService } from '@app-services'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-lang-switch',
    templateUrl: './lang-switch.component.html',
    styleUrls: ['./lang-switch.component.scss'],
})
export class LangSwitchComponent implements OnDestroy {

    public showDropdown: boolean = false

    public readonly countries: {
        label: string
        value: 'NL' | 'ISE'
    }[] = [
            {
                label: 'common.netherlands-and-belgium',
                value: 'NL',
            },
            {
                label: 'common.international',
                value: 'ISE',
            },
        ]

    public readonly langList = locales
    public readonly langLabels: { [K in Localization.Locale]: string } = {
        nl: 'Nederlands',
        en: 'English',
        de: 'Deutsch',
        fr: 'Français',
    }
    public currentCountry: 'NL' | 'ISE'
    public currentCountrySub: Subscription

    constructor(
        public readonly localization: LocalizationService,
        private readonly debtorService: DebtorService,
        public readonly authService: AuthService,
    ) {
        this.currentCountrySub = this.debtorService.isNL
            .subscribe(value => this.currentCountry = value ? 'NL' : 'ISE')
    }

    public async setLanguage(lang: Localization.Locale): Promise<void> {
        await this.localization.setLocale(lang)
    }

    public setCountry(country: 'NL' | 'ISE'): void {
        this.currentCountry = country
        this.debtorService.setCountry(this.currentCountry)
    }

    public ngOnDestroy(): void {
        this.currentCountrySub.unsubscribe()
    }
}
