<a #link
   (click)="onClick(value)"
   tabindex="0"
   aria-hidden="true"
   role="option"
   [class.selected]="(selectControlRegistry.activeItem$ | async) === this"
   [class.child]="isChild"
   [attr.aria-label]="label"
>
    <img *ngIf="image" [src]="image" alt="{{ label }}" class="option-image" />

    {{ label }}
</a>
