import { Observable, startWith } from 'rxjs'
import { map } from 'rxjs/operators'

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { FocusableOption } from '@angular/cdk/a11y'

import { SelectControlRegistryService } from '@app-domains/ui/services/select-control-registry/select-control-registry.service'
import { delay } from '@app-lib/common.lib'

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent<T = unknown> implements OnChanges, FocusableOption {
    @Input()
    public label: string

    @Input()
    public image?: string

    @Input()
    public value: T

    @Input()
    public disabled: boolean

    @Input()
    public control: FormControl

    @Input()
    public isChild: boolean = false

    /**
     *
     * @type {EventEmitter<string>}
     */
    @Output()
    public valueUpdated = new EventEmitter<T>()

    @ViewChild('link', {
        static: true,
    })
    public link: ElementRef<HTMLAnchorElement>

    public checked$: Observable<boolean>
    public selected: boolean

    constructor(
        public selectControlRegistry: SelectControlRegistryService<T>,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.control && ! this.checked$) {
            this.checked$ = this.control.valueChanges.pipe(
                startWith(this.control.value),
                map((v) => v === this.value),
            )
        }
    }

    public getLabel(): string {
        return this.label
    }

    public onFocus(): void {
        if (this.selectControlRegistry.keyManager) {
            this.selectControlRegistry.keyManager.setActiveItem(this)
        }
    }

    public onClick(value: T): void {
        this.selectControlRegistry.close()
        this.valueUpdated.emit(value)
        this.onFocus()
    }

    public async focus(): Promise<void> {
        await delay(0)
        this.link.nativeElement.focus()
    }
}
